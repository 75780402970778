import React from 'react'
import Layout from '../../../components/Layout'

const SuccessPage = () => {
  return (
    <Layout>
      <section className='hero is-primary is-large'>
        <div className='hero-body'>
          <h1 className='anthracite-text title'>
            Danke für Ihre Nachricht.
          </h1>
        </div>
      </section>
      <div className='yellow-border' />
      <section className='section section--gradient without-vertical-padding'>
        <div className='container'>
          <div className='section'>
            <div className='columns'>
              <div className='column is-12'>
                <div className='content'>
                  <div className='text-center'>
                    <h3 className='extra-light is-size-4 anthracite-text text-center'>
                      Wir melden uns baldmöglich zurück.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SuccessPage
